import React, { useState} from 'react';
import * as Images from './imageAssets.js';

import './css_HomePage.css';
import './css_Navbar.css';
import './css_Footer.css';
import './css_Sponsoring.css';
import './css_Events.css';
import './css_Contacts.css';
import './css_AboutUs.css';
// $env:NODE_OPTIONS = "--openssl-legacy-provider"

function App() {
  const [activeSection, setActiveSection] = useState("KermesseNatoye2k23");
  const [menuActive, setMenuActive] = useState(false);

  const handleNavClick = (section) => {
    setActiveSection(section);
    setMenuActive(false);
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  
//--------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------MAIN-----------------------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------------//
  return (
    <>
    <head>
      <title>Jeunesse de Natoye</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"></meta>
    </head>
    <Navbar 
        activeSection={activeSection}
        handleNavClick={handleNavClick}
        menuActive={menuActive}
        toggleMenu={toggleMenu}
      />
      <main>
        <KermesseNatoye2k23 activeSection={activeSection} />
        <Sponsoring activeSection={activeSection} />
        <Events activeSection={activeSection} />
        <Aboutus activeSection={activeSection} />
        <Contacts activeSection={activeSection} />
        <Footer />
      </main>
    </>
  );
}
//----------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------NAVBAR-----------------------------------------------------------//
//----------------------------------------------------------------------------------------------------------------------------//
function Navbar({ activeSection, handleNavClick, menuActive, toggleMenu }) {
  return (
<header>
        <div className="header-left">
          <div className="logo">
            <img src={Images.LogoSiteBlanc2} alt="Logo Jeunesse de Natoye blanc" />
          </div>
          <nav>
            <ul className={menuActive ? "active" : ""}>
              <li>
              <a
                href="#KermesseNatoye2k23"
                className={activeSection === "KermesseNatoye2k23" ? "active" : "" + menuActive ? "" : " hidden"}
                onClick={() => handleNavClick("KermesseNatoye2k23")}
              >
              Kermesse 2k24
              </a>
              </li>

              <li>
              <a
                  href="#sponsoring"
                  className={activeSection === "sponsoring" ? "active" : ""}
                  onClick={() => handleNavClick("sponsoring")}
                >
                sponsoring
                </a>
              </li>
              <li>
              <a
                  href="#events"
                  className={activeSection === "events" ? "active" : ""}
                  onClick={() => handleNavClick("events")}
                >
                évènements passés
                </a>
              </li>
              <li>
                <a
                  href="#jeunesse"
                  className={activeSection === "jeunesse" ? "active" : ""}
                  onClick={() => handleNavClick("jeunesse")}
                >
                  maison des jeunes
                </a>
              </li>
              <li>
                <a
                  href="#contacts"
                  className={activeSection === "contacts" ? "active" : ""}
                  onClick={() => handleNavClick("contacts")}
                >
                  contacts
                </a>
              </li>
            </ul>
            <img src={Images.MHb} alt="Menu Hamburger" className='MH' onClick={toggleMenu}/>
          </nav>
        </div>
      </header>
  );
}
//----------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------FOOTER-----------------------------------------------------------//
//----------------------------------------------------------------------------------------------------------------------------//

function Footer() {
  return (
    <footer>
    <div class="contenu-footer">
      <div class="footer-medias">
          <ul class="liste-reseaux">
              <li><a href="https://www.facebook.com/JeunesseDeNatoye" target="_blank"><img src={Images.fcbw}/></a></li>
              <li><a href="https://www.instagram.com/jeunesse_de_natoye" target="_blank"><img src={Images.instaw}/></a></li>
              <li><a href="https://www.tiktok.com/@jeunesse_de_natoye" target="_blank"><img src={Images.tiktokw}/></a></li>
          </ul>
      </div>
      <div class="logofooter">
          <img src={Images.logoBlanc}/>
      </div>
      <div class="fcontacts">
          <a href="mailto:mdj-natoye@outlook.be">mdj-natoye@outlook.be</a>
      </div>
    </div>
    <div class="FinDePage">
      <a href='https://www.linkedin.com/in/lucas-fadeux-ab9009286/' target="_blank"><p>© Lucas Fadeux. Tous droits réservés</p></a>
    </div>
  </footer>
  );
}
//----------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------HOMEPAGE-----------------------------------------------------------//
//----------------------------------------------------------------------------------------------------------------------------//

function KermesseNatoye2k23({ activeSection }) {
  return (
    <section id="index" className={`header-space ${activeSection === "KermesseNatoye2k23" ? "active" : ""}`}>
    <div className={`HP`}>
      <div className='headHP'>
        <div className='image'>
        <h1>Préventes</h1>
        </div>
        <div className='Ticket'>
          <a href="https://www.livecafe.be/billetterie/jeunesse-de-natoye" target="_blank"><img src={Images.LCnoir} alt="LIVECAFE"/></a>
          <h3>Pass week-end : 18 €</h3>
          <h3>Vendredi : 10 €</h3>
          <h3>Samedi : 11 €</h3>
        </div>
      </div>
      <div className='LineUp'>
        <h1>Lineup</h1>
      </div>
      <div className='container2'>
        <div class="C_1">
          <img src={Images.AM_img}/>
          <div class="C_1_1">
          <a href="https://www.facebook.com/arnovsmass" target="_blank"><img src={Images.fcbw}/></a>
          <a href="https://www.instagram.com/arno_metropolis/" target="_blank"><img src={Images.instaw}/></a>
          <a href="https://soundcloud.com/arno_metropolis/arno-mass-nty-2k22" target="_blank"><img src={Images.SCw}/></a>
          </div>
        </div>
        <div class="C_2">
          <img src={Images.Lunax_img}/>
          <div class="C_1_1">
          <a href="https://www.instagram.com/lunax_music/" target="_blank"><img src={Images.instaw}/></a>
          <a href="https://open.spotify.com/intl-fr/artist/7CLsFRcEkn0Amc9VlVOFwR" target="_blank"><img src={Images.Spotyw}/></a>
          <a href="https://www.youtube.com/channel/UCXO8r9oAmQ1NhCv1viBNv9w" target="_blank"><img src={Images.YTBw}/></a>
          </div>
        </div>
        <div class="C_3">
          <img src={Images.OHKAR_img}/>
          <div class="C_1_1">
          <a href="https://www.instagram.com/omdathetkanaveragerob/" target="_blank"><img src={Images.instaw}/></a>
          <a href="https://open.spotify.com/intl-fr/artist/2F0R31A3u4zGhd2uzxKigJ" target="_blank"><img src={Images.Spotyw}/></a>
          <a href="https://www.youtube.com/@AverageRob" target="_blank"><img src={Images.YTBw}/></a>
          </div>
        </div>
        <div class="C_4">
          <img src={Images.Ctrack_img}/>
          <div class="C_1_1">
          <a href="https://www.instagram.com/dj.c.track/" target="_blank"><img src={Images.instaw}/></a>
          <a href="https://soundcloud.com/djctrack" target="_blank"><img src={Images.SCw}/></a>
          <a href="https://www.youtube.com/channel/UCOOy-rTrbZF9qWlNtKr4CDQ" target="_blank"><img src={Images.YTBw}/></a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="LU left">
          <h3>Vendredi</h3>
          <a href="https://www.instagram.com/allox_music/" target="_blank"><img src={Images.DJNTY} alt="Allox"/></a>
          <a href="https://www.facebook.com/benjamin.bartiaux" target="_blank"><img src={Images.BENB} alt="Ben-B"/></a>
          <a href="https://www.instagram.com/lunax_music/" target="_blank"><img src={Images.Lunax} alt="Lunax"/></a>
          <a href="https://www.facebook.com/arnovsmass" target="_blank"><img src={Images.AM} alt="Arno & Mass"/></a>
          <a href="https://www.facebook.com/gregory.halin.7" target="_blank"><img src={Images.greg} alt="DJ Greg"/></a>
        </div>
        <div class="LU center">
          <h3>Samedi</h3>
          <a href="https://www.instagram.com/allox_music/" target="_blank"><img src={Images.DJNTY} alt="Allox"/></a>
          <a href="https://www.facebook.com/BeljonsProject" target="_blank"><img src={Images.Beljons} alt="Beljons"/></a>
          <a href="https://www.instagram.com/dj.c.track/" target="_blank"><img src={Images.Ctrack} alt="C-track"/></a>
          <a href="https://www.instagram.com/omdathetkanaveragerob/" target="_blank"><img src={Images.OHKAR} alt="Omdat Het Kan & Average Rob"/></a>
          <a href="https://www.facebook.com/gregory.halin.7" target="_blank"><img src={Images.greg} alt="DJ Greg"/></a>
        </div>
        <div class="LU right">
          <h3>Dimanche</h3>
          <img src={Images.corse} alt="LIVECAFE"/>
          <a href="https://www.facebook.com/profile.php?id=100091535763515" target="_blank"><img src={Images.rbjj} alt="RBJJ"/></a>
        </div>
     </div>
    </div>
    </section>
  );
}
//----------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------SPONSOR----------------------------------------------------------//
//----------------------------------------------------------------------------------------------------------------------------//
function Sponsoring({ activeSection }) {
  return (
    <section className={`header-space ${activeSection === "sponsoring" ? "active" : ""}`}>
      <div className='bodySponsoring'>
        <h2>Nos partenaires principaux</h2>
        <div className="LogoSpP">
          <a href="https://www.bc-immo.be/" target="_blank"><img src={Images.BC} alt="Belpaire et Clarival"/></a>
          <a href="https://www.facebook.com/profile.php?id=100030283064827" target="_blank"><img src={Images.PL} alt="Sébastien Lahaut"/></a>
          <a href="https://www.florianhuet.be/" target="_blank"><img src={Images.FH} alt="Florian Huet"/></a>
          <a href="https://www.take-eat.be/" target="_blank"><img src={Images.TakeEat} alt="Take-Eat"/></a>
          <a href="https://www.dachelet.be/" target="_blank"><img src={Images.Dach} alt="Dachelet"/></a>
          <a href="https://www.facebook.com/profile.php?id=100083514304791" target="_blank"><img src={Images.Baudoin} alt="Louis Baudoin"/></a>
          <a href="https://wallux.com/brasserie-balleux-beauraing" target="_blank"><img src={Images.Balleux} alt="Balleux"/></a>
          <a href="https://www.facebook.com/AssurancesRudyDewez" target="_blank"><img src={Images.Dewez} alt="Dewez assurances srl"/></a>
          <a href="" target="_blank"><img src={Images.EquiWat} alt="Equiwat"/></a>
          <a href="https://www.facebook.com/Fritkotlarecreciney" target="_blank"><img src={Images.LaRecre} alt="La Récré"/></a>
          <a href="https://www.facebook.com/cashexpressandenne" target="_blank"><img src={Images.CE} alt="Cash Express Andenne"/></a>
          <a href="https://www.mazout-botton.be" target="_blank"><img src={Images.MazoutB} alt="Mazout Botton"/></a>
          <img src={Images.CLwood} alt="CLwood"/>
          <a href="https://www.facebook.com/profile.php?id=100091535763515" target="_blank"><img src={Images.RBJJ} alt="RBJJ"/></a>
          
          
        </div>
        <h2>Affichage sur le site de l'évènement</h2>
        <div className="Showroom">
              <img src={Images.SH1}/>
              <img src={Images.SH2}/>
              <img src={Images.SH3}/>
        </div>
        <div className='OR'>
          <p>Les images proposées ont été photographiées par</p>
          <a  href="https://www.facebook.com/OverviewRealisation" target="_blank"><img src={Images.OR}></img></a>
        </div>
      </div>
    </section>
  );
}
//----------------------------------------------------------------------------------------------------------------------------//
//-----------------------------------------------------------EVENTS-----------------------------------------------------------//
//----------------------------------------------------------------------------------------------------------------------------//
function Events({ activeSection }) {
  return (
    <section className={`header-space ${activeSection === "events" ? "active" : ""}`}>
      <div className='bodyEvent'>
        <h2>Aftermovie 2023</h2>
        <div className='boxvid'>
          <video controls poster={Images.Couv}>
            <source src={Images.vid2023} type="video/mp4" />
            Votre navigateur ne prend pas en charge la balise vidéo.
        </video>
        </div>
        <h2>Mix disponible ici </h2>
        <div className='Mixx'>
        <div className='Mix'>
          <a href='https://soundcloud.com/arno_metropolis/arno-mass-nty-2k22' target='blank_'><img src={Images.AM22}></img><img src={Images.SoundCloud}></img></a>
        </div>
        <div className='Mix'>
          <a href='https://soundcloud.com/mass_be/arno-mass-nty-2k23' target='blank_'><img src={Images.AM23}></img><img src={Images.SoundCloud}></img></a>
        </div>
        <div className='title'>
          <h3>Nos dernières kermesses</h3>
        </div>
        <div className='Affiches0'>
          <div className='Affiches'>
            <img src={Images.Aff2023}></img>
            <img src={Images.Aff2022}></img>
            <img src={Images.Aff2021}></img>
            <img src={Images.Aff2019}></img>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}

function Aboutus({ activeSection }) {
  return (
    <section className={`header-space ${activeSection === "jeunesse" ? "active" : ""}`}>
      <div className='bodyJeunesse'>
        <div className="Group">
          <div className="GroupText">
            <h2>La jeunesse de Natoye</h2>
          </div>
        </div>
        <div className='AboutUs'>
          <div className="AUleft">
          <h1>Qui sommes-nous?</h1>
            <p> La jeunesse de Natoye c'est :<br />
                50 membres actifs de 16 à 30 ans (et parfois plus)<br />
                Une trentaine d'anciens, parents et bénévoles<br />
                Une ambiance de folie<br />
                Des soirées et activités en tout genre<br />
            </p>
          <div className='JoinUs'>
          <h1>Envie de nous rejoindre ?</h1>
            <p> Tu es dans l'année de tes 16 ans ? <br />
                Tu es de Natoye ou de ses alentours?<br />
                Tu es motivé et disposé à participer à l'organisation de plusieurs évènements?<br />
                Tu aimes faire la fête ?<br /><br/>
                Alors rejoins notre équipe de folie en nous contactant via notre page <a href='https://www.facebook.com/JeunesseDeNatoye' target='_blank'>Facebook</a> !<br/><br/> 
                Candidature à envoyer après la kermesse et avant le mois de juillet !
            </p>
          </div>
          </div>
          <div className="AUright">
            <div className='slider'>
              <div className='slider-track'>
                <img src={Images.slide1}></img>
                <img src={Images.slide2}></img>
                <img src={Images.slide3}></img>
                <img src={Images.slide4}></img>
                <img src={Images.slide5}></img>
                <img src={Images.slide6}></img>
                <img src={Images.slide7}></img>
                <img src={Images.slide8}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Contacts({ activeSection }) {
  return (
    <section className={`header-space ${activeSection === "contacts" ? "active" : ""}`}>
      <div className='bodyContact'>
      <h1>Contactez-nous !</h1>
        <div className='contactbox'>
          <div className='mail'>
            <img src={Images.email}></img>
            <div className='mailtext'>
              <p>Pour des demandes de sponsoring, collaborations, prestations artistiques, envoyez-nous un e-mail !</p>
              <a href="mailto:mdj-natoye@outlook.be">mdj-natoye@outlook.be</a>
            </div>
          </div>
          <div className='fcb'>
            <img src={Images.fcbw}></img>
            <div className='fcbtext'>
              <p>Pour des questions rapides qui touchent à nos évènements, passez par notre page Facebook</p>
              <a href="https://www.facebook.com/JeunesseDeNatoye" target="_blank"> Jeunesse de Natoye</a>
            </div>
          </div>
        </div>
      <h1>Suivez-nous !</h1>
        <div className='followbox'>
          <div className='insta'>
            <div className='langInsta'>
            <a href='https://www.instagram.com/jeunesse_de_natoye' target='_blank'><img src={Images.instaw}></img></a>
              <div className='ftInsta'>
                <h2>jeunesse_de_natoye</h2>
              </div>
            </div>
            <div className='Circle'>
            <img id='QRinsta' src={Images.QRinstaNoir}></img>
            </div>
          </div>
          <div className='snap'>
            <div className='langSnap'>
              <div className='ftSnap'>
                <h2>jeunesse_natoye</h2>
              </div>
              <a href='https://www.snapchat.com/add/jeunesse_natoye' target='_blank'><img src={Images.Bitmoji} id='bitmoji'></img></a>
            </div>
            <div className='Circle2'>
            <img id='snapcode' src={Images.Snapcode}></img>
            </div>
            </div>
          <div className='tiktok'>
            <div className='langTikTok'>
            <a href='https://www.tiktok.com/@jeunesse_de_natoye' target='_blank'><img src={Images.tiktokw}></img></a>
              <div className='ftTikTok'>
                <h2>jeunesse_de_natoye </h2>
              </div>
            </div>
            <div className='Circle'>
            <img id='QRTT' src={Images.QRTT}></img>
            </div>
          </div>
          <h4>Scanne les codes QR pour ne rien rater !</h4>
        </div>
      </div>
    </section>
  );
}
export default App;
